import {
  Button,
  FormHelperText,
  Grid2,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import React, { useState } from "react";

import FormTextField from "../formElements/textfield";
import { LoadingButton } from "@mui/lab";

function SelectPlanComp({ activeStep, setActiveStep, handleBack, isLoading }) {
  const [showPromo, setShowPromo] = useState(false);
  const {
    trigger,
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const {
    field: { onChange, value },
  } = useController({
    name: "plan_id",
    control,
    defaultValue: "",
  });

  const handleNextClick = async () => {
    const isValid = await trigger(["plan_id"]);
    if (isValid) {
      setActiveStep(activeStep + 1);
    }
  };

  const plans = [
    {
      value: "Free",
      planName: "Free",
      planAmount: "$0.00",
      planDetail: "30-day free trial",
    },
    {
      value: "price_1QNGnnFogknCIxGn0uZTutaL",
      planName: "Monthly",
      planAmount: "$49.99",
      amountSuffix: "/month",
    },
    {
      value: "price_1QNGnnFogknCIxGnFsGSOst4",
      planName: "Annually",
      planAmount: "$499.99",
      amountSuffix: "/year",
      planDetail: "Save over 15%",
    },
  ];

  return (
    <Stack className="height100">
      <div className="steps_center-content">
        <Typography variant="h6" mb={0.5} style={{ fontSize: 24 }}>
          Select a Plan
        </Typography>
        <Typography variant="body1" mb={3} className="lead-text">
          Enjoy a <strong>30-day free trial!</strong> Billing begins after your
          free trial ends, and you’ll only be charged once the trial is over.
          You can cancel anytime during the trial period at no cost.
          Cancellations after the trial will take effect on your next billing
          anniversary.
        </Typography>

        <Stack spacing={2} flexGrow={"1"}>
          <div>
            <Grid2 container spacing={1}>
              {plans.map((ele) => (
                <Grid2 size={{ md: 4, sm: 12, xs: 12 }}>
                  <label for={`radio-${ele?.value}`} className="radio-plan">
                    <input
                      type="radio"
                      name="plan_id"
                      id={`radio-${ele?.value}`}
                      value={ele?.value}
                      onChange={onChange}
                      checked={value == ele?.value}
                    />
                    <div className="card-content-wrapper">
                      <span className="check-icon"></span>
                      <div className="card-content">
                        <h4>{ele?.planName}</h4>
                        <div>
                          <h5>
                            {ele?.planAmount}
                            <span>{ele?.amountSuffix}</span>
                          </h5>
                          <p className="plan-badge">{ele?.planDetail}</p>
                        </div>
                      </div>
                    </div>
                  </label>
                </Grid2>
              ))}
            </Grid2>
            {errors.plan_id && (
              <FormHelperText error>{errors.plan_id.message}</FormHelperText>
            )}
            <div className="my-2" onClick={() => setShowPromo(true)}>
              <Link href="#" variant="body1" className="my-2">
                Have a promo code?
              </Link>
            </div>

            {showPromo && (
              <FormTextField name="promo_code" label="Promo Code" />
            )}

            {watch("promo_code") && (
              <FormHelperText error>promo code is not valid</FormHelperText>
            )}
          </div>
        </Stack>
      </div>

      <Stack direction="row" justifyContent="flex-end">
        <Button variant="text" onClick={handleBack} sx={{ mr: 1 }} size="large">
          Back
        </Button>
        {watch("plan_id") === "Free" ? (
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            size="large"
          >
            Submit
          </LoadingButton>
        ) : (
          <Button variant="contained" onClick={handleNextClick} size="large">
            {"Next"}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

export default SelectPlanComp;
